$structure-gray: #d0d0d0;
$branding-gold: #d5b477;
$container-bg: #ffffff;
$link-padding-tb: 3rem;
$link-padding-lr: 2rem;

@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
  -webkit-transition: $what $time $how;
  -moz-transition: $what $time $how;
  -ms-transition: $what $time $how;
  -o-transition: $what $time $how;
  transition: $what $time $how;
}

/* --- Layout --- */

.banner {
  height: 4rem;
  width: 100%;
}

.main {
  display: flex;
  align-items: flex-start;
  padding: 0;
}

.nav {
  flex: 2;
}

.content {
  flex: 3;
  padding: 0 2%;
  border: 1px solid $structure-gray;
  background-color: $container-bg;
  display: none;
}

.active .content {
  display: block;
}

.active .nav {
  display: none;
}

/* --- Banner --- */

.banner__back {
  position: fixed;
  height: 3rem;
  font-size: 1.5em;
  z-index: 3;
  color: #fff;
  background-color: $branding-gold;
  padding: 0 1.4rem 0 1rem;
  top: .5rem;
  line-height: 3rem;
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;

  @include transition (all, .5s);
  left: -100%;
}

.active .banner__back {
  left: 0;
}

.banner__content {
  width: 100%;
  font-size: 19px;
  height: 2rem;
  background-color: $container-bg;
  border-bottom: 1px solid $branding-gold;
  text-transform: uppercase;
  text-align: center;
  position: fixed;
  padding: 1rem 0;
  z-index: 2;
}

.logo {
  width: 79px;
  height: 20px;
  position: relative;
  top: 2px;
  margin-right: 8px;
}

/*--- Navigation --- */

ul.nav__links {
  list-style: none;
  border-left: none;
  border-right: none;
  margin: 0;
}

.nav__links li {
  border-bottom: 1px solid $structure-gray;
  background-color: $container-bg;
}

.article-link {
  position: relative;
  padding: $link-padding-lr $link-padding-lr $link-padding-lr calc(30% + #{$link-padding-lr});
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;

}

.article-link img {
  position: absolute;
  left: 0;
  top: 0;
  width: 30%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
}

.article-link span {
  position: relative;
  z-index: 1;
  font-size: 1.2em;
}

/* --- Body Content --- */

h1 {
  margin: 1em 0;
  text-align: center;
}

.content h2 {
  margin: 2.5rem 0 1rem 0;
}

.content__image {
  width: 100%;
}

ul.content__social {
  list-style: none;
}

ul.content__social li {
  float: right;
  margin-left: 1rem;
}

.content__article {
  margin: 3.5rem 0 2em 0;
  text-align: justify;
}

.content__article p {
  margin: 1rem 0;
}

.content__social li a {
  opacity: .7;
}

.content__social li a:hover {
  opacity: 1;
}

.icon_facebook {
  color: #3b5998;
}

.icon_twitter {
  color: #00aced;
}

.icon_rss {
  color: #f26522;
}

@media only screen and (min-width: 768px) {

  html {
    scroll-behavior: smooth;
  }

  .banner__content {
    text-align: left;
    padding-left: 1.5rem;
  }

  .banner__back {
    display: none !important;
  }

  .content, .nav {
    display: block !important;
  }

  .main {
    padding: 2rem;
  }

  .main__flex-item {
    margin: 1rem;
  }

  ul.nav__links {
    border: 1px solid $structure-gray;
    border-bottom: none;
  }

  .article-link {
    padding: $link-padding-tb $link-padding-lr;
  }

  .article-link img {
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
  }

  .article-link:hover {
    background-color: #e5e0d7;
  }

  .article-link:hover img {
    opacity: .1;
  }

  .article-link.active {
    color: #fff;
    background-color: #543e16;
    cursor: auto;
  }

  .article-link.active img {
    opacity: .4;
  }
}
