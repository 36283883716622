body {
  font-family: Pragati Narrow, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #585858;
  background-color: #eaeaea;
}

* {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Questrial, sans-serif;
}

ul {
  margin-left: 3em;
}

